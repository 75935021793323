import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/scout/beta',
    name: 'scout',
    component: HomeView,
  },
  {
    path: '/scout/app/beta-ios',
    name: 'ios-beta',
    component: () => import('../views/app/IosBetaView.vue'),
  },
  {
    path: '/scout/updates/e4985c4c-2799-4136-8e3f-6f8fbd36ac67',
    name: 'updates-feb-25',
    component: () => import('../views/updates/Feb25View.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
