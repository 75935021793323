<template>
  <div>
    <div id='stretch' class="position-relative pb-5">
      <div class="position-absolute w-100" id="video-overlay">
        <section id='sec0' ref='sec0' class="fixed-section" data-start="-1" data-end="150">
          <div class="container">
            <span id='beta'><small>Je bekijkt een betaversie</small></span>
            <div class="row">
              <div class="col-12 text-center mb-5">
                <h1 class="text-center">Hey, ik ben Scout.</h1>
                <p class='fade-in-delay'>Scroll om kennis te maken.</p>
              </div>
            </div>
          </div>
        </section>
        <section id='sec1' ref='sec1' class="fixed-section" data-start="1000" data-end="1800">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 class="text-center mb-5">Een autonome besturing voor boomgaardvoertuigen.</h1>
              </div>
            </div>
          </div>
        </section>
        <section id='sec2' ref='sec2' class="fixed-section" data-start="2700" data-end="3500">
          <div class="container d-flex align-items-end align-items-md-center h-100">
            <div class="row mb-5 mb-md-0">
              <div class="col-md-4 offset-md-1">
                <h2 class="h1">
                  Veilig.
                  Robuust.
                  Betrouwbaar.
                </h2>
                <p class='col-md-9'>
                  Focus op wat belangrijk is: de boomgaard. Scout neemt de besturing van het voertuig over. Eenvoudig aan te sluiten op de bestaande besturing van het voertuig, zonder de noodzaak voor extra instellingen, klaar voor gebruik.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id='sec3' ref='sec3' class="fixed-section" data-start="4370" data-end="5200">
          <div class="container d-flex align-items-end h-100">
            <div class="row mb-5">
              <div class="col-md-4">
                <h2 class="h1">
                  Sensor
                </h2>
                <p>
                  De sensor is in staat om dag en nacht in de meeste weersomstandigheden de omgeving te scannen.
                </p>
              </div>
              <div class="col-md-4 offset-md-4 text-end">
                <h2 class="h1">
                  Processor
                </h2>
                <p>
                  De processor zet de sensordata om in een route en stuurt het hydraulische stuursysteem aan. 
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id='sec4' ref='sec4' class="fixed-section" data-start="6200" data-end="6800">
          <div class="container">
            <div class="row">
              <div class="col-md-4 offset-md-4">
                <h2 class="h1 text-center">
                  Recht door zee
                </h2>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ScrollyVideo :src='require("@/assets/videos/hero-neotex.mp4")' id='pxl' class='pxl-class' :frameThreshold='0.01'/>
    </div>
    <div class="container container-theme-black pb-5">
      <div class="row">
        <div class="col-12 text-center">
          
          <h2 class="h1 pt-5">Meer dan een rechte lijn</h2>
        </div>
      </div>
      <div class="row p-0">
        <div class="col-12 column-grid" id='meer-dan-grid'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M128 112a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM128 0a64 64 0 1 1 0 128A64 64 0 1 1 128 0zM47.3 176L40.7 336H64h48 31.4l16 16H152V496h32V376.6l16 16V504v8h-8H144h-8v-8V352H120V504v8h-8H64 56v-8V352H40 24l.7-16L32 160H224l1.3 30.2-13.5 13.5-1.8 1.8L208.7 176H47.3zM440 504V392.6l16-16V496h32V352h-7.4l16-16H528h48 23.3l-6.7-160H431.3l-1.2 29.5-1.8-1.8-13.5-13.5L416 160H608l7.3 176 .7 16H600 584V504v8h-8H528h-8v-8V352H504V504v8h-8H448h-8v-8zM104 352H72V496h32V352zM512 112a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM512 0a64 64 0 1 1 0 128A64 64 0 1 1 512 0zm56 496V352H536V496h32zM245.7 237.7L187.3 296H452.7l-58.3-58.3-5.7-5.7L400 220.7l5.7 5.7 72 72 5.7 5.7-5.7 5.7-72 72-5.7 5.7L388.7 376l5.7-5.7L452.7 312H187.3l58.3 58.3 5.7 5.7L240 387.3l-5.7-5.7-72-72-5.7-5.7 5.7-5.7 72-72 5.7-5.7L251.3 232l-5.7 5.7z"/></svg>
            <h2>Personenherkenning</h2>
            <p>
              Mensen die om het voertuig heen werken worden herkend en Scout houdt hier rekening mee in de route. Zo kan er veilig gewerkt worden.
            </p>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M106.6 119.8l-79 13.2 46.1 65.9 6.4 9.2-6.4 9.2L27.7 283.1l79 13.2 11.3 1.9 1.9 11.3 13.2 79L160 369.4v19.5l-24.1 16.9L121.3 416l-2.9-17.5L104 312 17.5 297.6 0 294.7l10.2-14.5L60.7 208 10.2 135.9 0 121.3l17.5-2.9L104 104l14.4-86.5L121.3 0l14.5 10.2L208 60.7l72.1-50.5L294.7 0l2.9 17.5L307.8 79c-5.1 2.7-10 5.8-14.7 9.2L283.1 27.7 217.2 73.8 208 80.2l-9.2-6.4L132.9 27.7l-13.2 79-1.9 11.3-11.3 1.9zM208 112c18.4 0 35.6 5.2 50.2 14.1c-2.7 4.6-5.2 9.3-7.4 14.3C238.4 132.5 223.7 128 208 128c-44.2 0-80 35.8-80 80c0 26.6 13 50.1 32.9 64.7c-.6 5-.9 10.1-.9 15.3v3.2c-28.7-16.6-48-47.6-48-83.2c0-53 43-96 96-96zM368 96c30.3 0 57.3 14 74.9 35.9c2.7 3.3 5.1 6.8 7.3 10.4c2.9-2 5.9-3.9 9.1-5.5c11-5.7 23.5-8.9 36.7-8.9c44.2 0 80 35.8 80 80c0 3.7-.3 7.4-.8 11c-.3 2.1-.7 4.2-1.1 6.2c2.1 .4 4.2 .8 6.2 1.4c34.3 9 59.7 40.2 59.7 77.4l0 64 0 16h-16l-64 0H544v-16h15.9l64 0v-64c0-31.5-22.7-57.6-52.7-63L554.8 238l3.6-16.3c1-4.4 1.5-9 1.5-13.8c0-28.3-18.3-52.2-43.7-60.7l1.2-5-5 3.9c-5.3-1.4-10.8-2.1-16.5-2.1c-13.6 0-26.2 4.3-36.6 11.5l-14 9.8-8.8-14.6C422.4 127.5 397 112 368 112c-44.2 0-79.9 35.8-80 79.9l0 1.3 .1 13.6-13.4 2.3c-37.8 6.3-66.6 39.3-66.6 78.9v80h48v16H208 192v-16-80c0-47.6 34.6-87 80-94.6l0-1.3c0-53 43-96 96-96zM342.3 486.9L364 400l4-16H351.5 304 288V368v-8L443.6 239.8 464 224l-6.3 25.1L436 336l-4 16h16.5H496h16v16 8L356.4 496.2 336 512l6.3-25.1zM368 368h20.5l-5 19.9-20.8 83.3L496 368.1V368H432 411.5l5-19.9 20.8-83.3L304 367.9v.1h64z"/></svg>
            <h2>All-seasons</h2>
            <p>
              Boomgaarden komen in vele vormen en maten. Zelfs dezelfde gaard ziet er door het jaar heen anders uit. Scout is ontworpen om met deze veranderingen om te gaan, zodat het voertuig altijd de juiste route rijdt.
            </p>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16 224C16 109.1 109.1 16 224 16h24c95.7 0 172.5 77.9 176 172.7l.1 3.4 2.6 2.3L496 256v64H440h-8v8V432H312h-8v8 64 8h16v-8V448H440h8v-8V336h56 8v-8V252.4v-3.6l-2.7-2.4-69.5-61.8C434.3 83.3 351.6 0 248 0H224C100.3 0 0 100.3 0 224c0 59.1 23.7 114.5 64 156.7V504v8H80v-8V377.5v-3.3l-2.3-2.3C38.7 332.1 16 279.7 16 224zM192 96v8 29.6c-11 4.4-20.9 10.2-30.3 17.5l-25.6-14.8-6.9-4-4 6.9-24 41.6-4 6.9 6.9 4 25.6 14.8c-.9 5.6-1.6 11.5-1.6 17.5s.7 11.9 1.6 17.5l-25.6 14.8-6.9 4 4 6.9 24 41.6 4 6.9 6.9-4 25.6-14.8c9.3 7.3 19.2 13.1 30.3 17.5V344v8h8 48 8v-8V314.4c11-4.4 20.9-10.2 30.3-17.5l25.6 14.8 6.9 4 4-6.9 24-41.6 4-6.9-6.9-4-25.6-14.8c.9-5.6 1.6-11.5 1.6-17.5s-.7-11.9-1.6-17.5l25.6-14.8 6.9-4-4-6.9-24-41.6-4-6.9-6.9 4-25.6 14.8c-9.3-7.3-19.2-13.1-30.3-17.5V104 96h-8H200h-8zm16 16h32v27.1 5.7l5.3 1.9c13.1 4.6 24.3 11.1 34.9 20.2l4.3 3.7 4.9-2.8L313 154.1l16 27.7-23.5 13.6-4.9 2.8 1 5.6c1.3 7.1 2.4 13.7 2.4 20.2s-1 13.1-2.4 20.2l-1 5.6 4.9 2.8L329 266.1l-16 27.7-23.5-13.6-4.9-2.8-4.3 3.7c-10.6 9.1-21.8 15.6-34.9 20.2l-5.3 1.9v5.7V336H208V308.9v-5.7l-5.3-1.9c-13.2-4.6-24.3-11.1-34.9-20.2l-4.3-3.7-4.9 2.8L135 293.9l-16-27.7 23.5-13.6 4.9-2.8-1-5.6c-1.3-7.1-2.4-13.7-2.4-20.2s1-13.1 2.4-20.2l1-5.6-4.9-2.8L119 181.9l16-27.7 23.5 13.6 4.9 2.8 4.3-3.7c10.6-9.1 21.8-15.6 34.9-20.2l5.3-1.9v-5.7V112zm16 88a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 64a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>
            <h2>Slimme padvolging</h2>
            <p>
              Op basis van de voertuiggegevens en het bijbehorende stuurgedrag stuurt Scout het voertuig vooruitkijkend aan. Hierdoor wordt er in het midden van het pad gereden en minimaal gestuurd.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container container-theme-white py-5">
      <div class="row">
        <div class="col-md-5 d-flex align-items-end">
          <div>
            <div class="fiddle"></div>
            <h2 class="h1">Kies voor bedrijfszekerheid</h2>
            <p>
              Om jouw investeringen verder te verbeteren, heeft Scout geen abonnementen of onderhoudskosten nodig, in tegenstelling tot GPS-gebaseerde navigatiesystemen. We weten dat er ook boomgaarden buiten de bewoonde wereld liggen, daarom berekent Scout het pad volledig lokaal. Er is daardoor ook geen satelliet- of internettoegang nodig.
            </p>
          </div>
        </div>
        <div class="col-md-6 offset-md-1">
          <video src="@/assets/videos/wandering.mp4" class='img-fluid rounded-img' autoplay muted loop
            poster="@/assets/img/wandering.jpg"
          ></video>
        </div>
      </div>
    </div>
    <div class="container container-theme-white py-5">
      <div class="row">
        <div class="col-md-12">
          <h2 class="h1">Technische gegevens</h2>
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>Afmeting <i>lbh</i></td>
                <td>
                  Sensor: 16,2cm x 27,42cm x 12,94cm
                  <br>
                  Processor: 16cm x 12cm x 4,5cm
                </td>

              </tr>
              <tr>
                <td>Bedrijfstemperatuurbereik</td>
                <td>-10°C tot +50°C</td>
              </tr>
              <tr>
                <td>Stroomverbruik</td>
                <td>6W</td>
              </tr>
              <tr>
                <td>Bandnauwkeurigheid</td>
                <td>±5 cm - CI 95%</td>
              </tr>
              <tr>
                <td>Water- en stofbestendigheid (Sensor)</td>
                <td>IP65</td>
              </tr>
              <tr>
                <td>Rijbreedte boomgaard</td>
                <td>1m - 6m</td>
              </tr>
              <tr>
                <td>Boomafstand</td>
                <td>0.5m - 6m</td>
              </tr>
              <tr>
                <td>Montagehoogte vanaf de grond</td>
                <td>25cm - 2m</td>
              </tr>
              <tr>
                <td>MTBF</td>
                <td>40.000 uur</td>
              </tr>
              <tr>
                <td>Vcc</td>
                <td>9 - 36V</td>
              </tr>   
              <tr>
                <td>Interface</td>
                <td>Twee potentiaalvrije contacten</td>
              </tr>            
            </tbody>
          </table>

        </div>
      </div>
    </div>

    <div class="container container-theme-black py-5">
      <div class="row">
        <div class="col-md-6 offset-md-3 page-section d-flex align-items-center text-center">
          <div class="text-center w-100">
            <span class="text-secondary">
              Ontwikkelingsstatus
            </span>
            <h2 class="h1 text-center">
              Beschikbaar vanaf medio 2024
            </h2>
            <a href="mailto:hallo@neotex.nl" target='_blank' class="btn" id='contact-chip'>
              Neem contact op <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' style='margin-left:20px;' height="20" width="20" viewBox="0 0 512 512"><path d="M509.7 261.7c3.1-3.1 3.1-8.2 0-11.3l-152-152c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L484.7 248 8 248c-4.4 0-8 3.6-8 8s3.6 8 8 8l476.7 0L346.3 402.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0l152-152z"/></svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.vue';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger'; 

export default {
  name: 'HomeView',
  components: {
    ScrollyVideo
  },
  mounted: function() {
    gsap.registerPlugin(ScrollTrigger);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      let lastScrollTop = 0;
      document.querySelectorAll('.fixed-section').forEach(section => {
        const start = section.dataset.start;
        const end = section.dataset.end;

        gsap.timeline({
          scrollTrigger: {
            trigger: section.id,
            start: start + " top",
            end: end + " top",
            scrub: true, 
            onEnter: () => gsap.to(section, { autoAlpha: 1 }),
            onLeave: () => gsap.to(section, { autoAlpha: 0 }),
            onEnterBack: () => gsap.to(section, { autoAlpha: 1 }),
            onLeaveBack: () => gsap.to(section, { autoAlpha: 0 }),
            // markers: true // Remove this line in production
          }
        });
      });

      // at the end of #stretch, add a class to #pxl 
      gsap.timeline({
        scrollTrigger: {
          trigger: '#stretch',
          start: 'top bottom',
          end: 'bottom bottom',
          scrub: true,
          // markers: true,
          onUpdate: (self) => {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const isScrollingDown = currentScrollTop > lastScrollTop;

            if (self.progress > 0.95 && isScrollingDown) {
              // Add classes when scrolling down and progress is greater than 0.95
              document.getElementById('pxl').classList.add('end-of-stretch', 'container');
            } else {
              // Remove classes otherwise
              document.getElementById('pxl').classList.remove('end-of-stretch', 'container');
            }

            // Update the last scroll position why? : 
            // lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
          }
        }
      });
    }
  }
}
</script>

<style scoped>
section{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-section{
  min-height: 300px;
  height: 40vh;
}
#stretch {
  width: 100%;
  position: relative;
  top: 0;
  height: 8200px;
  background-color: #000000;
}
#video-overlay {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 8200px;
}
#pxl {
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 100%;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  transition: all 0.5s ease-in-out;
  margin: 0 auto;
}
#pxl.end-of-stretch {
  height: calc(100vh - 200px) !important;
  top: 100px !important;
  border-radius: 150px;
  width: 100%;
}



@media screen and (min-width: 768px) {
  #pxl.end-of-stretch {
    max-width: 720px;
  }
}


@media screen and (min-width: 992px) {
  #pxl.end-of-stretch {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  #pxl.end-of-stretch {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  #pxl.end-of-stretch {
    max-width: 1320px;
  }
}

@media screen and (max-width: 768px) {
  #pxl.end-of-stretch {
    height: calc(50vh - 100px) !important;
    top: 50px !important;
    border-radius: 75px;
  }
}
.fixed-section{
  position: fixed;
  z-index: 2;
  visibility: hidden;
  top: 0;
}
#dus{
  z-index: 3;
}
#strech video {
  display: none !important;
}

#meer-dan-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 20px; */
  margin-top: 3rem;
}

@media screen and (max-width: 768px) {
  #meer-dan-grid {
    grid-template-columns: 1fr;
  }
  
}

#meer-dan-grid svg{
  width: 40px;
  height: 32px;
  fill: #2e74dd;
}

.fade-in-delay {
  animation: fadeIn 1s ease-in-out 2s forwards;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#beta{
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

/* #contact-chip {
  background: none; 
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
} */
</style>